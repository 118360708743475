import React, { FC } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

type SEOProps = {
  pageTitle?: string
}
export const SEO: FC<SEOProps> = ({ pageTitle }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(metaDataQuery)
  const {
    title,
    description,
    siteUrl,
    facebookUsername,
    instagramUsername,
  } = site.siteMetadata

  const seo = {
    title: pageTitle ?? title,
    description: description,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      <meta name="facebook:card" />
      {facebookUsername && (
        <meta name="facebook:creator" content={facebookUsername} />
      )}
      {seo.title && <meta name="facebook:title" content={seo.title} />}
      {seo.description && (
        <meta name="instagram:description" content={seo.description} />
      )}
      <meta name="instagram:card" />
      {instagramUsername && (
        <meta name="instagram:creator" content={instagramUsername} />
      )}
      {seo.title && <meta name="instagram:title" content={seo.title} />}
      {seo.description && (
        <meta name="instagram:description" content={seo.description} />
      )}
    </Helmet>
  )
}

const metaDataQuery = graphql`
  query SEO {
    site {
      id
      siteMetadata {
        title
        description
        siteUrl
        facebookUsername
        instagramUsername
      }
    }
  }
`
